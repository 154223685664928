<template>
	<Table
		:pgLimitGet="pgLimit"
		backendDirectory="aluguel-equipamento/lista"
		:busca="{
            busca: busca.busca || null
        }"
		:filter="true"
		:keydown="keydown"
		:headers="{
            idequipamento: {nome: 'ID', tipo: permissao('aluguel_equipamento_get') ? 'link' : 'texto', link: '/aluguel-equipamento/lista/get/'},
            equipamento: {nome: 'Equipamento', tipo: 'texto'},
            status: {nome: 'Status', tipo: 'texto'},
            dataaquisicao: {nome: 'Data aquisição', tipo: 'texto'},
            custoaquisicao: {nome: 'Custo aquisição', tipo: 'dinheiro'},
            custoreposicao: {nome: 'Custo reposição', tipo: 'dinheiro'},
            despmanutencao: {nome: 'Despesa manutenção', tipo: 'dinheiro'},
        }"
	>
		<v-col class="d-none d-md-block pr-1">
			<InputDatePicker :outlined="true" :dense="true" v-model="busca.dtinicio" label="Data inicial" />
		</v-col>
		<v-col class="d-none d-md-block px-1">
			<InputDatePicker :outlined="true" :dense="true" v-model="busca.dtfim" label="Data final" />
		</v-col>
		<v-col class="d-none d-md-block px-1">
			<v-select
				clearable
				:items="[]"
				item-text="status"
				item-value="idstatus"
				label="Status"
				outlined
				dense
				v-model="busca.idstatus"
			></v-select>
		</v-col>
		<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
			<v-sheet class="text-center" height="100%">
				<v-row class="pa-3">
					<v-col cols="12" class="pr-3 pr-md-0 mb-n8">
						<v-select clearable :items="[]" label="Status" outlined dense v-model="busca.idstatus"></v-select>
					</v-col>
					<v-col cols="6">
						<InputDatePicker
							v-model="busca.dtinicio"
							label="Data inicial"
							:outlined="true"
							:dense="true"
						/>
					</v-col>
					<v-col cols="6">
						<InputDatePicker v-model="busca.dtfim" label="Data final" :outlined="true" :dense="true" />
					</v-col>
					<v-col cols="6">
						<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
					</v-col>
					<v-col cols="6">
						<v-btn text class="mt-n3" color="primary" @click="keydown = !keydown, sheet = !sheet">Filtrar</v-btn>
					</v-col>
				</v-row>
			</v-sheet>
		</v-bottom-sheet>
		<v-col class="pl-1">
			<v-text-field
				class="d-flex d-md-none"
				v-model="busca.busca"
				label="Buscar"
				outlined
				dense
				append-outer-icon="mdi-menu"
				@click:append-outer="sheet = !sheet"
				@keydown.enter="keydown = !keydown"
			/>
			<v-text-field
				class="d-none d-md-block"
				v-model="busca.busca"
				label="Buscar"
				outlined
				dense
				@keydown.enter="keydown = !keydown"
			/>
		</v-col>
	</Table>
</template>

<script>
import { mapState } from "vuex";
import InputDatePicker from "../Widgets/InputDatePicker";
import Table from "../Widgets/Table";

export default {
	name: "AluguelEquipamentoLista",
	components: { Table, InputDatePicker },
	data: () => ({
		sheet: false,
		keydown: false,
		busca: {
			dtinicio: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-01`,
			dtfim: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getDate()}`,
			idstatus: null,
		},
	}),
	computed: {
		...mapState(["pgLimit"]),
	},
	created() {},
};
</script>

<style scoped>
</style>